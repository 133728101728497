body{
    background-color: #474747;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
}

:focus, :active, :hover {
    background: transparent;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) and (pointer: fine) {
    :focus, :active, :hover {
        background: transparent;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
    }
}

#app{
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #474747;
}

#main{
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #ffffff;
    overflow: hidden;
}

#house{
    position: relative;
    width: 100%;
    height: 100vh;
    background: url(../img/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.show{
    display: block !important;
}

.hide{
    display: none !important;
}

#video-container{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
}

#video-container .screensaver, #video-container .technique{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#video-container .assistant{
    position: absolute;
    top: 46.7%;
    left: 50.15%;
    transform: translate(-50%, -50%);
}

#stores{
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    top: -40%;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    background: url(../img/store.png);
    background-repeat: no-repeat;
    background-size: cover;
    transform: skewY(5deg);
    transition: top 3s linear 1s, transform 3s linear 1s;
}

#stores.on {
    transform: skewY(0deg);
    top: 0;
}

#light{
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100vh;
    background: url(../img/light.png);
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity 500ms linear 1s;
}

#light.on {
    opacity: 1;
}

#heater{
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100vh;
    background: url(../img/heater.png);
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity 3s linear 2s;
}

#heater.on {
    opacity: 1;
}


/* Zooms */
@keyframes zoominout-store {
    0% {
        -webkit-transform: translate(0,0) scale(1,1);
        transform: translate(0,0) scale(1,1);
    }
    20%, 80% {
        -webkit-transform: translate(10%,5%) scale(1.1,1.1);
        transform: translate(10%,5%) scale(1.1,1.1);
    }
    100% {
        -webkit-transform: translate(0,0) scale(1,1);
        transform: translate(0,0) scale(1,1);
    }
}
.zoom-store {
    animation: zoominout-store 6s linear;
}

@keyframes zoominout-light {
    0% {
        -webkit-transform: translate(0,0) scale(1,1);
        transform: translate(0,0) scale(1,1);
    }
    20%, 70% {
        -webkit-transform: translate(2%,10%) scale(1.1,1.1);
        transform: translate(2%,10%) scale(1.1,1.1);
    }
    100% {
        -webkit-transform: translate(0,0) scale(1,1);
        transform: translate(0,0) scale(1,1);
    }
}
.zoom-light {
    animation: zoominout-light 3s linear;
}

@keyframes zoominout-heater {
    0% {
        -webkit-transform: translate(0,0) scale(1,1);
        transform: translate(0,0) scale(1,1);
    }
    20%, 80% {
        -webkit-transform: translate(-10%,5%) scale(1.1,1.1);
        transform: translate(-10%,5%) scale(1.1,1.1);
    }
    100% {
        -webkit-transform: translate(0,0) scale(1,1);
        transform: translate(0,0) scale(1,1);
    }
}
.zoom-heater {
    animation: zoominout-heater 6s linear;
}



/* Btns */
.video-buttons{
    position: absolute;
    display: block;
    width: 117px;
    height:auto;
    top: 50%;
    right: 0px;
    z-index: 999;
    transform: translate(-0, -50%);
}


#btnassistant{
    display: inline-block;
    width: 117px;
    height: 400px;
    background: url(../img/bouton-assistant.png);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin-bottom: 10px;
}


#btntechnique{
    display: inline-block;
    width: 117px;
    height: 300px;
    background: url(../img/bouton-technique.png);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin-top: 10px;
}
